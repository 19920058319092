*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden
}