@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Jost&family=Roboto&family=Outfit&family=Open+Sans&family=Poppins&display=swap');
$green: #286a51;
ul {
    list-style: none;
    display: flex;
}

section {
    width: 85%;
    margin: 0 auto;
    padding: 3rem 0;
}

a {
    text-decoration: none;
    color: inherit;
}

button,
input {
    border: none;
    outline: none;
    font-family: "Jost";
    cursor: pointer;
}

h2 {
    font-family: 'DM Sans', sans-serif;
}

h4,
h5,
h6,
p {
    font-family: "DM Sans";
    font-weight: 500;
}

li {
    cursor: pointer;
}

a,
span,
li {
    font-family: "DM Sans";
}

img {
    width: 100%;
    height: 100%;
}

.full {
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(https://img.freepik.com/free-photo/dark-green-texture_1205-338.jpg?1?w=360);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    min-height: 100vh;
    padding: 2rem 0;
    .headline {
        color: #fff;
    }
    .tophead {
        color: #dbdbdb;
    }
    .divider {
        background: #fff;
    }
}

.menu-btn {
    display: none;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    width: 85%;
    margin: 0 auto;
    .nav-links {
        ul {
            gap: 1rem;
            a {
                text-transform: capitalize;
            }
        }
    }
}

.appear {
    display: none;
}

.logo {
    display: flex;
    gap: 2px;
    margin-left: -10px;
    align-items: center;
    img {
        width: 20px;
        height: 20px;
    }
    h4 {
        font-size: 1.4rem;
    }
}

.btn {
    text-transform: capitalize;
    background: #fff;
    color: #035133;
    border-radius: 20px;
    font-family: "Jost";
    font-size: 1rem;
    padding: 10px 1rem;
    cursor: pointer;
}

.home {
    margin-top: 4rem;
    display: grid;
    place-items: center;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    .home-text {
        h2 {
            font-size: 3rem;
            line-height: 3.5rem;
            text-transform: capitalize;
        }
        p {
            font-size: 1.25rem;
            margin: 1rem 0;
        }
        button {
            margin: 1rem 0;
        }
    }
    .moto {
        margin: 1rem 0;
        padding-left: 1rem;
        ul {
            list-style: disc;
            gap: 1rem;
        }
        li {
            margin-right: 10px;
        }
        h6 {
            font-size: 1.2rem;
        }
    }
    .home-image {
        img {
            border-radius: 10px;
        }
    }
}

.about-grid {
    margin-top: 3rem;
}

.headline {
    font-size: 2rem;
    color: $green;
    text-transform: capitalize;
}

.tophead {
    text-transform: uppercase;
    font-size: 1rem;
    color: #035133;
    opacity: 0.6;
}

.grid {
    display: grid;
    place-items: center;
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 10px;
}

.divider {
    height: 2px;
    width: 70px;
    background: $green;
}

.offer {
    padding-top: 4.5rem;
    .offer-grid {
        width: 100%;
        height: 100%;
        margin-top: 3rem;
    }
    .offer-item {
        display: grid;
        grid-template-columns: 40% 60%;
        gap: 3rem;
        position: relative;
        padding-left: 8rem;
        &::after {
            background-color: #000000;
            bottom: 2px;
            content: "";
            position: absolute;
            top: 11px;
            left: 47%;
            -webkit-transition: height 0.5s ease-out 2s;
            -moz-transition: height 0.5s ease-out 2s;
            transition: height 0.5s ease-out 2s;
            width: 1px;
            margin-top: -5px;
        }
        &::before {
            background-color: #000000;
            content: "";
            display: block;
            height: 9px;
            left: 47%;
            position: absolute;
            transform: translateX(-4px);
            width: 9px;
            margin-right: 2rem;
        }
    }
    .image {
        width: 300px;
        height: 200px;
        img {
            object-fit: cover;
        }
    }
    .text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-left: 1rem;
        h6 {
            text-transform: capitalize;
            font-weight: 700;
            font-size: 1rem;
        }
        h4 {
            font-size: 1.4rem;
        }
        p {
            font-size: 1rem;
            width: 80%;
        }
    }
}

img {
    border-radius: 20px;
}

.feature {
    .divider {
        background: #fff;
    }
}

.feature-container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.four-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    margin-top: 3rem;
    .center {
        gap: 10px;
    }
}

.item {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 100%;
    margin: 1rem 0;
    h4 {
        color: #16b276;
    }
    span {
        width: fit-content;
        background: #16b276;
        padding: 1rem;
        display: flex;
        align-items: center;
        border-radius: 50%;
        svg {
            font-size: 1.4rem;
            color: #fff;
        }
    }
    h6 {
        text-transform: capitalize;
        font-size: 1.25rem;
    }
    p {
        line-height: 2rem;
        opacity: 0.8;
    }
}

.sector {
    padding: 4rem 0;
}

.sector-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 3rem 0;
    .item {
        display: flex;
        text-align: center;
        align-items: center;
    }
}

.download {
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
        img {
            border-radius: 15px;
            margin-top: 1rem;
        }
    }
}

.flex {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
}

.google-link,
.flex {
    p {
        font-size: 1.25rem;
        margin: 1rem 0;
    }
    img {
        width: 140px;
        height: 60px;
    }
}

.google-btn {
    transition: all 0.3s ease;
    &:hover {
        transform: scale(0.9);
        transition: all 0.3s ease;
    }
}

.app-icon {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 6px 8px;
    border-radius: 5px;
    border: 1px solid #1212;
    transition: all 0.3s ease;
    svg {
        font-size: 1.35rem;
    }
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &:hover {
        transform: scale(0.9);
        transition: all 0.3s ease;
    }
}

.faq-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    .question {
        padding: 2rem 0;
        border-bottom: 1px solid #808080;
        cursor: pointer;
        width: 700px;
    }
    .header {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        margin: 0 auto;
        align-items: center;
        .heading {
            display: flex;
            align-items: center;
            gap: 1rem;
            width: 100%;
        }
        svg {
            font-weight: 700;
            font-size: 0.8rem;
        }
        h5 {
            font-weight: 500;
            font-size: 1.25rem;
        }
        h6 {
            font-size: 1.25rem;
            font-weight: 500;
        }
    }
    p {
        margin-top: 10px;
    }
    button {
        display: flex;
        align-items: center;
        background: #16b276;
        border-radius: 50%;
        color: #fff;
        padding: 8px;
    }
}

footer {
    background: #035133;
    color: #fff;
    margin-top: 3rem;
    padding-top: 3rem;
    section {
        padding-bottom: 1rem;
    }
    .foot-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
        text-transform: capitalize;
        ul {
            flex-direction: column;
            gap: 1rem;
            margin-top: 1rem;
        }
        h6 {
            font-size: 1.4rem;
            font-weight: 600;
        }
    }
    .between {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        ul {
            gap: 1rem;
        }
        p {
            text-transform: capitalize;
        }
    }
}

@media screen and (max-width: 1000px) {
    .offer {
        .offer-item {
            padding-left: 4rem;
        }
    }
}

@media screen and (max-width: 900px) {
    section {
        width: 90%;
    }
    .home {
        .home-text {
            h2 {
                font-size: 2.5rem;
            }
        }
    }
    .four-grid,
    .sector-container,
    footer .foot-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 900px) {
    .menu-btn {
        display: flex;
    }
    .nav-links {
        width: 0;
        height: 0;
        overflow: hidden;
        transition: width 0.5s ease;
    }
    .navbar {
        display: flex;
        width: 85vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        flex-direction: column;
        justify-content: right;
        align-items: flex-start;
        gap: 3rem;
        padding: 6rem 2rem 2rem;
        background: #fff;
        z-index: 100;
        transition: width 0.5s ease;
        ul {
            flex-direction: column;
            width: 100%;
        }
        li {
            color: #111;
            border-bottom: 1px solid #035133;
            padding-bottom: 1rem;
            a {
                font-size: 1.5rem;
            }
        }
    }
    .appear {
        display: flex;
    }
    .disappear {
        display: none;
    }
    .home {
        grid-template-columns: 100%;
        .home-text {
            h2 {
                font-size: 3rem;
            }
        }
        .home-image {
            display: none;
        }
    }
    .offer {
        .offer-item {
            grid-template-columns: 100%;
            padding-left: 1rem;
            .image {
                margin-left: 2rem;
            }
            &::after,
            &::before {
                left: 7%;
            }
        }
    }
    .faq-container {
        .question {
            width: 100%;
        }
    }
}

@media screen and (max-width: 500px) {
    .home {
        .home-text {
            h2 {
                font-size: 2.5rem;
            }
        }
        .moto {
            h6 {
                font-size: 0.7rem;
            }
        }
    }
    .four-grid {
        gap: 1rem;
    }
    .grid {
        gap: 1rem;
        grid-template-columns: repeat(1, 1fr);
    }
    .four-grid,
    .sector-container,
    footer .foot-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    footer .foot-grid {
        place-items: start;
    }
    footer {
        .between {
            justify-content: center;
            width: 100%;
            p {
                text-align: center;
            }
            ul {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    section {
        width: 92.5%;
    }
    .logo {
        h4 {
            font-size: 1.1rem;
        }
    }
    .header {
        .btn {
            font-size: 0.75rem;
            padding: 8px 14px;
            margin-right: -1rem;
        }
    }
    .home {
        .home-text {
            h2 {
                font-size: 2.25rem;
            }
            ul {
                gap: 10px;
            }
        }
    }
    .headline {
        font-size: 1.4rem;
    }
    .tophead {
        font-size: 0.75rem;
    }
    .offer {
        .offer-grid {
            margin-top: 5rem;
        }
        .offer-item {
            .image {
                margin-left: 1rem;
                margin-top: 1rem;
            }
            &::after,
            &::before {
                left: 5%;
            }
        }
    }
    .offer {
        .text {
            p {
                font-size: 0.8rem;
            }
        }
    }
    .item {
        p {
            line-height: 1.2rem;
            font-size: 0.9rem;
        }
    }
    .google-link {
        p {
            font-size: 1rem;
        }
    }
    .faq-container {
        p {
            margin-top: 1rem;
            font-size: 0.8rem;
        }
        .header {
            h6 {
                font-size: 0.9rem;
            }
        }
    }
    footer {
        .footgrid {
            h6 {
                font-size: 1.1rem;
            }
            li {
                font-size: 0.85rem;
            }
        }
    }
}

.line {
    height: 1px;
    width: 100%;
    background-color: #808080;
    margin-top: 3em;
}

.fixed {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 5rem;
    background: #fff;
    z-index: 10;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 20%);
    .btn {
        border: 1px solid #035133;
    }
    li {
        color: #111;
    }
    .logo {
        color: #111;
    }
    .menu-btn {
        svg {
            color: #111;
        }
    }
    @media screen and (max-width: 500px) {
        height: 4rem;
    }
}

.menu-btn {
    border: none;
    outline: none;
    background: none;
    svg {
        color: #fff;
        font-size: 1.3rem;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    .modal-container {
        background: #041804;
        color: #fff;
        width: 70%;
        margin: 0 auto;
        padding: 2rem 1rem;
        border-radius: 10px;
        @media screen and (max-width: 500px) {
            width: 90%;
            padding: 1.5rem;
        }
    }
    form {
        width: 300px;
        @media screen and (max-width: 500px) {
            width: 250px;
        }
        margin: 0 auto;
        .form-input {
            width: 100%;
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center;
            input {
                border-radius: 10px;
                width: 95%;
                margin: 8px auto;
                padding: 1rem;
            }
        }
    }
    svg {
        color: red;
        font-size: 1.6rem;
    }
    h4 {
        text-transform: capitalize;
    }
}

.policy {
    .article {
        margin: 1rem 0;
    }
    h4 {
        margin-bottom: 1rem;
    }
    ul {
        flex-direction: column;
        list-style: disc;
        margin-left: 2rem;
        li {
            margin: 0.5rem 0;
        }
    }
    h5 {
        font-weight: 800;
        text-transform: capitalize;
    }
    .grey {
        margin: 1rem 0;
        font-size: 0.9rem;
        color: #808080
    }
}